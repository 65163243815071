import { Button, Heading, HStack, Spinner, Tag, VStack, Text, useToast } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import useSingleCourse from "../../../hooks/useSingleCourse"
import { RCLASS_TO_COLOR } from "../../../lib/CONSTANTS"
import { useEffect, useState } from "react"
import GeneratedResourceDataForm from "../add_resource/GeneratedResourceDataForm"
import { updateCourseGeneratedData } from "../../../lib/database"


export default function EditGeneratedResourceData() {

    const toast = useToast()
    const { courseId, resourceName, resourceClass } = useParams()
    const [courseData, isCourseLoading, generatedCourseData] = useSingleCourse(courseId, true, false)

    const [generatedData, setGeneratedData] = useState(undefined)
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const onClickSave = () => {
        if (isSaving) return

        setIsSaving(true)
        updateCourseGeneratedData(courseId, resourceClass, resourceName, generatedData)
            .then(() => {
                toast({
                    title: 'Generated Data Updated',
                    status: 'success',
                    isClosable: true,
                    duration: 5000,
                    position: 'top'
                })
            })
            .catch((error) => {
                toast({
                    title: 'Something went wrong',
                    description: error,
                    status: 'error',
                    isClosable: true,
                    duration: 5000,
                    position: 'top'
                })
            })
        setIsSaving(false)
    }

    useEffect(() => {
        if (!isCourseLoading) {
            setGeneratedData(generatedCourseData[resourceClass][resourceName])
        }
    }, [isCourseLoading, generatedCourseData])

    if (isCourseLoading || generatedData === undefined) return <Spinner />

    return (
        <VStack maxW={'3xl'} m='auto'>
            <Heading>
                Edit Generated Resource Data for
            </Heading>
            <HStack alignItems={'center'}>
                <Tag colorScheme={RCLASS_TO_COLOR[resourceClass]}>{resourceClass}</Tag>
                <Text>{' / '}</Text>
                <Tag colorScheme={'primary'}>{resourceName}</Tag>
            </HStack>
            <GeneratedResourceDataForm
                generatedData={generatedData}
                setGeneratedData={setGeneratedData}
            />
            <Button onClick={onClickSave} fontWeight={'400'} colorScheme="primary">{isSaving ? <Spinner /> : 'Save'}</Button>
        </VStack>
    )
}
