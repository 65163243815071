import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Button, FormControl, FormLabel, HStack, IconButton, Input, Textarea, Text, VStack, useColorModeValue, Box } from "@chakra-ui/react";

type FieldConfig = {
    field: string
    // type: 'singleLine' | 'multiLine' | 'stringList'
    Component: React.FC<({ value: string[] | string, onValueChange: (v: string[] | string) => void, title: string, description?: string })>
    description?: string
}

const UPDATEABLE_FIELDS: FieldConfig[] = [
    {
        field: "stub",
        Component: StringSingleLineInput,
        description: "This is the short text description that is shown to students to describe a resource on the start page."
    },
    // {
    //     field: 'summary',
    //     Component: StringMultiLineInput
    // },
    {
        field: 'questions',
        description: "These are the written conceptual questions that ATA cross-checks student queries against for security. The system works best when the entire question and context is included.",
        Component: MultiStringInput
    }

]

export default function GeneratedResourceDataForm({ generatedData, setGeneratedData }) {

    const updateField = (field: string, newValue: string | string[]) => {
        const tmp = { ...generatedData }
        tmp[field] = newValue
        setGeneratedData(tmp)
    }

    return (
        <VStack align={'stretch'} w='full' gap='6'>
            {UPDATEABLE_FIELDS.map(({ field, Component, description = undefined }, i) => Object.keys(generatedData).includes(field) ? <Component description={description} key={i} title={field} value={generatedData[field]} onValueChange={(v) => updateField(field, v)} /> : <Text key={i}>This resource doesn't have {field}</Text>)}
        </VStack>
    )

}

function MultiStringInput({ value, onValueChange, title, description = undefined }: { value: string[], onValueChange: (s: string[]) => void, title: string, description?: string }) {
    const descriptionColor = useColorModeValue('slate.600', 'slate.300')

    const updateString = (index: number, newValue: string) => {
        const updatedList = [...value]
        updatedList[index] = newValue
        onValueChange(updatedList)
    }

    const deleteString = (index: number) => {
        const updatedList = value.filter((_, i) => i !== index)
        onValueChange(updatedList)
    }

    const addString = () => {
        onValueChange([...value, ""])
    }

    return (
        <FormControl w='full'>
            <Box mb='3'>
                <FormLabel mb='0' fontWeight={'bold'}>{title}</FormLabel>
                {description && <Text fontSize={'sm'} color={descriptionColor}>{description}</Text>}
            </Box>
            <VStack align="start" spacing={4}>
                {value.map((string, index) => (
                    <HStack key={index} width="100%">
                        <Textarea
                            value={string}
                            onChange={(e) => updateString(index, e.target.value)}
                            placeholder={`Item ${index + 1}`}
                        />
                        <IconButton
                            icon={<DeleteIcon />}
                            aria-label="Delete string"
                            onClick={() => deleteString(index)}
                            colorScheme="red"
                        />
                    </HStack>
                ))}
                <Button
                    onClick={addString}
                    leftIcon={<AddIcon />}
                    colorScheme="blue"
                    size="sm"
                >
                    Add String
                </Button>
            </VStack>
        </FormControl>
    )
}

function StringSingleLineInput({ value, onValueChange, title, description = undefined }: { value: string, onValueChange: (v: string) => void, title: string, description?: string }) {
    const descriptionColor = useColorModeValue('slate.600', 'slate.300')

    return (
        <FormControl w='full'>
            <Box mb='3'>
                <FormLabel mb='0' fontWeight={'bold'}>{title}</FormLabel>
                {description && <Text fontSize={'sm'} color={descriptionColor}>{description}</Text>}
            </Box>
            <Input
                type="text"
                value={value}
                onChange={e => onValueChange(e.target.value)}
            />
        </FormControl>
    )
}

function StringMultiLineInput({ value, onValueChange, title, description = undefined }: { value: string, onValueChange: (v: string) => void, title: string, description?: string }) {
    const descriptionColor = useColorModeValue('slate.600', 'slate.300')

    return (
        <FormControl w='full'>
            <Box mb='3'>
                <FormLabel mb='0' fontWeight={'bold'}>{title}</FormLabel>
                {description && <Text fontSize={'sm'} color={descriptionColor}>{description}</Text>}
            </Box>
            <Textarea
                value={value}
                onChange={e => onValueChange(e.target.value)}
                h='40'
            />
        </FormControl>
    )
}