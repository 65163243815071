import { ChatIcon, SettingsIcon, HamburgerIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { createIcon, Button, Flex, VStack, Heading, Divider, Box, Icon, IconButton, Link, useBreakpointValue, useColorModeValue, Hide, Img, color, Spacer, useBreakpoint, useMediaQuery, Toast } from "@chakra-ui/react"
import { FaHome, FaChartLine, FaQuoteRight, FaUser, FaPenAlt } from "react-icons/fa"
import { IoIosChatboxes, IoIosGrid, IoMdGrid } from "react-icons/io"
import { matchPath, Outlet, useLocation, useMatch, useNavigate, useParams } from "react-router-dom"
import { Link as ReactRouterLink } from 'react-router-dom'
import { BiConversation } from "react-icons/bi"
import { useEffect, useState } from "react"
import useUserDoc from "../../hooks/useUserDoc"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../lib/firebase"
import { isMainThread } from "worker_threads"
import useUserCourseDoc from "../../hooks/useUserCourseDoc"
import useSingleCourse from "../../hooks/useSingleCourse"
import { RiFileList2Fill, RiSurveyFill, RiSurveyLine } from "react-icons/ri"
import { BsBoundingBoxCircles, BsFillGridFill } from "react-icons/bs"

const HamburgerWithRightArrow = () => {
    return (
        <Box display="flex" alignItems="center" m={1}>
            <Icon as={HamburgerIcon} boxSize={5} m={-1} />
            <Icon as={ChevronRightIcon} boxSize={4} />
        </Box>
    );
}

const HamburgerWithLeftArrow = () => {
    return (
        <Box display="flex" alignItems="center" m={1}>
            <Icon as={ChevronLeftIcon} boxSize={4} m={-1} />
            {/* <Icon viewBox="0 0 24 24" >
            <circle cx="12" cy="5" r="2" />
            <circle cx="12" cy="12" r="2" />
            <circle cx="12" cy="19" r="2" />
        </Icon> */}
        </Box>
    );
}

const HamburgerArrowIcon = createIcon({
    displayName: "HamburgerArrowIcon",
    viewBox: "0 0 24 24",
    d: "M3 6h18M3 12h18M3 18h12M18 6l5 6-5 6",
});

export type NavItemData = {
    title: string
    SideIcon: any
    dividerItem: boolean
    to: string,
    onClick?: () => void
}

const STUDENT_COURSE_ITEMS: NavItemData[] = [
    {
        title: 'Start a Chat',
        SideIcon: ChatIcon,
        dividerItem: false,
        to: 'start'
    },
    {
        title: 'Chat History',
        SideIcon: IoIosChatboxes,
        dividerItem: false,
        to: 'chats'
    },
    // {
    //     title: 'Account',
    //     SideIcon: FaUser,
    //     dividerItem: false,
    //     to: 'account'
    // },
]

const ADMIN_LEVEL_1_ITEMS: NavItemData[] = [
    {
        dividerItem: true,
        SideIcon: undefined,
        title: undefined,
        to: undefined
    },
    {
        title: 'Usage',
        SideIcon: FaChartLine,
        dividerItem: false,
        to: 'admin/usage'
    },
    // {
    //     title: 'FAQs',
    //     SideIcon: FaQuoteRight,
    //     dividerItem: false,
    //     to: 'admin/faqs'
    // },
    {
        title: 'Users',
        SideIcon: FaUser,
        dividerItem: false,
        to: 'admin/users'
    },
    {
        title: 'Conversations',
        SideIcon: IoIosChatboxes,
        dividerItem: false,
        to: 'admin/conversations'
    },
    // {
    //     title: 'Clusters',
    //     SideIcon: BsFillGridFill,
    //     dividerItem: false,
    //     to: 'admin/clusters'
    // },
    {
        title: 'Resources',
        SideIcon: RiFileList2Fill,
        dividerItem: false,
        to: 'admin/resources'
    },
]

const ADMIN_LEVEL_2_ITEMS: NavItemData[] = [
    {
        title: 'Controls',
        SideIcon: SettingsIcon,
        dividerItem: false,
        to: 'admin/controls'
    },
]

const ADMIN_LEVEL_3_ITEMS: NavItemData[] = [
    {
        title: 'Feedback',
        SideIcon: RiSurveyFill,
        dividerItem: false,
        to: 'admin/feedback'
    },
    {
        title: 'Annotations',
        SideIcon: FaPenAlt,
        dividerItem: false,
        to: 'admin/annotations'
    }
]

export const ADMIN_LEVEL_TO_COURSE_NAV_ITEMS: { [key: number]: NavItemData[] } = {
    0: STUDENT_COURSE_ITEMS,
    1: ADMIN_LEVEL_1_ITEMS,
    2: ADMIN_LEVEL_2_ITEMS,
    3: ADMIN_LEVEL_3_ITEMS

}

function allowedToBeOnPage(courseId: string, page: string, adminLevel: number, coursePaused: boolean): boolean {
    if ((coursePaused && adminLevel <= 0) || adminLevel < 0) {
        return false
    }

    for (let i = 0; i <= adminLevel; i++) {
        for (const navItem of ADMIN_LEVEL_TO_COURSE_NAV_ITEMS[i]) {
            if (navItem.to === undefined) {
                continue
            }
            const match = matchPath(`/app/${courseId}/${navItem.to}/*`, page)
            if (match) {
                return true
            }
        }
    }

    return false
}

export default function Course() {
    const params = useParams()
    const dividerColor = useColorModeValue('slate.300', 'slate.600')

    const [user, userLoading, userError] = useAuthState(auth)
    const [course, courseLoading] = useSingleCourse(params.courseId)
    const [userCourseDoc, isUserCourseDoc] = useUserCourseDoc(user?.uid, params.courseId, courseLoading ? 15 : course.controls.quota.maxQueriesAccumulated)
    const [userDoc, isUserDoc] = useUserDoc(user)
    const location = useLocation()
    const [open, setIsOpen] = useState(false);
    const isExpandable = useBreakpointValue({ base: false, xl: true });

    const navigate = useNavigate()

    useEffect(() => {
        if (!courseLoading && isUserDoc) {
            if (!allowedToBeOnPage(params.courseId, location.pathname, userDoc.adminLevels[params.courseId], course.controls.paused)) {
                navigate("/app/dashboard")
            }
        }
    }, [course, courseLoading, isUserDoc, userDoc, location])


    const navItemsToShow = [...STUDENT_COURSE_ITEMS]

    if (isUserDoc && userDoc.adminLevels[params.courseId] > 0) {
        for (let i = 1; i <= userDoc.adminLevels[params.courseId]; i++) {
            navItemsToShow.push(...ADMIN_LEVEL_TO_COURSE_NAV_ITEMS[i])
        }
    }

    const isOpen = isExpandable ? open : false

    // console.log(matchPath(`/app/${params.courseId}/admin/resources/update`, location.pathname))

    return (
        <Flex h="full" position="relative">
            <Hide below="lg">
                <Box
                    bg={useColorModeValue("slate.100", "slate.800")}
                    transition="width 0.2s ease, padding 0.2s ease"
                    width={isOpen ? "270px" : "82px"}
                    p="0"
                    h="full"
                >
                    <VStack
                        h="100%"
                        px="4"
                        alignItems="stretch"
                        pt="6"
                        justifyContent="start"
                        gap="3"
                    >

                        {isExpandable && <Button
                            onClick={() => setIsOpen(!open)}
                            variant="unstyled"
                            w="full"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            {open ? <HamburgerWithLeftArrow /> : <HamburgerWithRightArrow />}
                        </Button>}

                        {navItemsToShow.map((item, i) =>
                            item.dividerItem ? (
                                <Divider key={i} borderColor={dividerColor} />
                            ) : (
                                <SidebarItem
                                    key={i}
                                    {...item}
                                    to={`/app/${params.courseId}/${item.to}`}
                                    text={isOpen}
                                />
                            )
                        )}
                    </VStack>
                </Box>
            </Hide>

            <Box
                overflowY={'scroll'}
                h="full"
                p={[4, null, null, 8]}
                m={[0, 0, 0, "0 0 0 0px"]}
                w='full'
                maxW='full'
                // w={isExpandable ? "calc(100vw - 560px)" : "100%"}
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Outlet />
            </Box>
        </Flex>
    )
}

export function SidebarItem({ title, SideIcon, to, onClick = undefined, text = true }) {

    const selected = useMatch(to || "this won't match anything")

    const bgRegular = useColorModeValue('slate.100', 'slate.800')
    const bgSelected = useColorModeValue('slate.200', 'slate.700')
    const bgHover = useColorModeValue('slate.200', 'slate.700')

    const colorRegular = useColorModeValue('slate.600', 'slate.200')
    const colorSelected = useColorModeValue('slate.600', 'slate.300')

    const border = useColorModeValue('slate.400', 'slate.600')

    return (
        <Link
            as={ReactRouterLink}
            to={to || "#"}
            onClick={onClick}
            bg={selected ? bgSelected : bgRegular}
            color={selected ? colorSelected : colorRegular}
            border='1px'
            borderColor={selected ? border : bgRegular}
            _hover={{ bg: selected ? bgSelected : bgHover }}
            fontWeight={'400'}
            display='flex'
            alignItems={'center'}
            textAlign={'left'}
            px='3'
            py='3'
            minWidth={text ? '50' : '12'}
            transition="0.2s ease"
            borderRadius={'2'}
        >
            <Icon as={SideIcon} ml={1} mr={text && 2} color={selected ? colorSelected : colorRegular} />
            <Box
                transition="opacity 0.2s ease, width 0.2s ease"
                opacity={text ? 1 : 0}
                width={text ? "auto" : "0px"}
                overflow="hidden"
                whiteSpace="nowrap"
            >
                {text ? title : '\u00A0'}
            </Box>
        </Link>
    )
}